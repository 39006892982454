import QRCode from 'react-qr-code';

import styles from './QRCodeContainer.module.scss';

function QRCodeContainer({
    wrapperClassNames, wrapperStyles, value = null, message,
}) {
    if (!value) {
        console.error('[QRCodeContainer] You must pass in a value!');

        return;
    }

    const customClass = wrapperClassNames ? ` ${ wrapperClassNames }` : '';

    return (
        <div style={wrapperStyles} className={`${ styles.container }${ customClass }`}>
            <div className={styles.QRWrapper}>
                <QRCode value={value} />
            </div>
            {message ? <p className="bold">{message}</p> : null}
        </div>
    );
}

export default QRCodeContainer;
